/* eslint-disable react/prop-types */
import React from 'react';
import {
  Projects, Wrapper,
} from '../components';
import getProjects from '../firebase/getProjects';

export default function ProjectsPage() {
  const {
    projects,
  } = getProjects();

  return (
    <Wrapper title="Projects">
      <Projects projects={projects} sm={6} />
    </Wrapper>
  );
}
